import { BreadcrumbGroup } from '@amzn/awsui-components-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Breadcrumbs = ({ isReportDetail }) => {
    const navigate = useNavigate();
    const pathname = window.location.pathname
    let crumbitems = [
    ]

    const href = pathname.split("/")[1];    // position 0 woulbe be empty

    crumbitems.push({ text: "My Reports", href: `/${href}` })

    if (isReportDetail) {
        crumbitems.push({ text: "Report Detail", href: "/" })
    }

    return (<BreadcrumbGroup
        onFollow={(event) => {
            event.preventDefault();
            navigate(event.detail.href)
        }}
        items={crumbitems}
        ariaLabel="Breadcrumbs"
    />
    );
}

export default Breadcrumbs;