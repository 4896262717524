import { Badge, Box, ButtonDropdown, Header, SpaceBetween } from "@amzn/awsui-components-react";
import { useSelector } from "react-redux";
import { selectUserAlias } from "../features/auth/userAliasSlice";

function UserProfile(props) {
    if (props.user) {
        return (
            <SpaceBetween direction="horizontal" size="s">
                <div><a href={`https://phonetool.amazon.com/users/${props.user}`} target='_blank' rel='noopener noreferrer'>
                    <img className="circular-portrait small" src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${props.user}`} alt='Phonetool' /></a></div>
                <div style={{ 'padding': '10px 0' }}><b>{props.user}</b></div>
            </SpaceBetween>
        );
    }

    //No user, return empty indicator
    return <b>--</b>;
}

export function MyHeader() {
    const userAlias = useSelector(selectUserAlias);

    return <Header className="header"
        variant="h1"
        actions={
            <Box>
                <SpaceBetween direction="horizontal" size="s">
                    <ButtonDropdown
                        items={[
                            {
                                text: "Report a bug",
                                href: "https://t.corp.amazon.com/create/templates/da0e9fde-48fb-410d-b671-754eb774e654",
                                external: true,
                                iconName: "bug",
                            },
                            {
                                text: "Request a feature",
                                href: "https://t.corp.amazon.com/create/templates/145b55db-a984-48e4-a09a-54091ad5ffb1",
                                external: true,
                                iconName: "heart"
                            }
                        ]}
                        variant="primary"
                        expandToViewport
                    >Contact Us</ButtonDropdown>
                    <UserProfile user={userAlias} />
                </SpaceBetween>
            </Box>
        }
    >
        <div>PANN Dashboard <Badge color="blue">BETA</Badge></div>
    </Header>
}