import React from 'react';
import {
    AppLayout,
    Box,
    Container,
    Grid,
    Header,
    Icon,
    Link,
    SpaceBetween
} from '@amzn/awsui-components-react';
import { Navigation } from '../../commons/navBar';
import { gridDefinition } from '../../commons/layout';

const Content = () => {

    return (
        <Box margin={{ top: 's' }} padding={{ top: 'xxl', horizontal: 's' }}>
            <Grid
                gridDefinition={[
                    gridDefinition
                ]}
            >
                <SpaceBetween size="l">
                    <Container
                        header={<Header>About PANN Dashboard</Header>} >
                        <Box variant="div" fontSize='heading-s' padding={{ horizontal: 'm' }}>
                            PANN Dashboard is a tool designed to allow user to analyze the applicability of vulnerabilities with patch information. It is currently back-ended by the <b>PANN (Patch Applicability Neural Network) API</b>, which is a long term machine learning model to identify if the vulnerabilities’ patches can be applicable to devices’ source code or not.
                            <br /><br />
                            PANN Dashboard now is able to support for analyzing <b>Linux Kernels’</b> vulnerability patch applicability on <b>FireOS 5, 6, 7, 8, NoART, EInk and Vega Devices that have internal code repo</b>.
                            <br /><br />
                            PANN Dashboard now accept three major types of use cases as follows:<br />

                            1. <b>Scan CVE(s)</b> : Linux Kernel CVE patch applicability on Devices <br/>
                            2. <b>Scan Security Tickets</b>: Linux Kernel patch applicability on Devices and Patch parsing from JIRA tickets <br/>
                            3. <b>Upload a Patch File</b>: Customized Private Linux Kernel patch applicability analysis on Devices <br/>

                            <br />
                            <b>Note:</b><br/>
                            The PANN Dashboard is engineered to select the most suitable patches for addressing vulnerabilities in a device's kernel source code. It identifies patches that best match the identified vulnerabilities and then work with our compilation check tool (C3) to evaluates their applicability of the compiled files. If a patch is only relevant to a module of the device kernel source that isn't compiled in device image, PANN will automatically exclude it. This selective approach ensures that only pertinent and compatible patches are applied, thus enhancing the patching process's efficiency and effectiveness. Consequently, if devices sharing the same kernel repository are reported with differing PANN statuses, it is recommended to review your kernel build configuration for potential discrepancies.
                        </Box>
                    </Container>

                    <Container
                        header={<Header>How to start PANN scan</Header>} >
                        <Box variant="p" fontSize='heading-s' padding={{ horizontal: 'm' }}>
                            <Icon name="upload" size="big" variant="normal" />
                            <br />
                            1. Upload CVEs, Jiras, or a patch file to scan
                            <br /><br />
                            <Icon name="check" size="big" variant="normal" />
                            <br />
                            2. View the PANN scan report once its state turns to "Ready"
                            <br /><br />
                            {/* <Icon name="share" size="big" variant="normal" />
                            <br /> */}
                            {/* 3. Share the report with others if you wish
                            <br /><br /> */}
                        </Box>
                    </Container>

                    <Container
                        header={<Header>PANN Dashboard Docs</Header>} >
                        <Box variant="p" fontSize='heading-s' padding={{ horizontal: 'm' }}>
                            <Link external href='https://w.amazon.com/bin/view/DevicesSecurityAutomation/PANN_Dashboard'>Wiki</Link>
                        </Box>
                    </Container>

                </SpaceBetween>
            </Grid>
        </Box>
    );
};

export function InfoPage() {
    const [navigationOpen, setNavigationOpen] = React.useState(true);
    return (
        <AppLayout
            stickyNotifications
            disableContentPaddings={true}
            content={<Content />}
            navigation={<Navigation activeHref="/info" />}
            navigationOpen={navigationOpen}
            navigationWidth={200}
            onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
            toolsHide={true}
        />
    );
}

