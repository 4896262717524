import React, { memo, useEffect } from "react";
import Flashbar from "@amzn/awsui-components-react/polaris/flashbar";

export default memo(function StatusBar({statusMsgObj}) {
    //Status bar on app layout header
    const [items, setItems] = React.useState([]);
    console.log(statusMsgObj)
    useEffect(() => {
        if (statusMsgObj && Object.keys(statusMsgObj).length > 0) { //Set status bar type (success/error) and message
            console.log("Set items in status bar")
            setItems([
                {
                    type: statusMsgObj.type,
                    content: statusMsgObj.message,
                    dismissible: true,
                    dismissLabel: "Dismiss message",
                    onDismiss: () => setItems([]),
                    action: statusMsgObj.action,
                    id: statusMsgObj.id ? statusMsgObj.id : 0
                }
            ]);
        }
    }, [statusMsgObj])

    return <Flashbar items={items} />;
})