import { getApiURL } from '../../utils/envVarsReader';
import axios from "axios";
import { getJwtToken } from '../auth/Auth';
// import mockCveDeviceReport from "./mockCveDeviceReport.json"
// import mockJiraReport from "./mockJiraReport.json"
// import mockPatchReport from "./mockPatchReport.json"


/**
 * Fetch report from server.
 * @returns {Response} Response object returned from calling lambda
 */
export async function fetchReport(reportId) {
    // if (getCurEnv() === 'local') {
    //     return new Promise((resolve, rej) => {
    //         resolve({
    //             'data': mockPatchReport
    //         })
    //     }
    //     ).then(res => {
    //         return res;
    //     })
    // }
    const options = {
        headers: {
            'Authorization': `Bearer ${getJwtToken()}`,
            'Content-Type': 'application/json'
        },
    }

    let response;
    try {
        response = await axios.get(`${getApiURL()}/report/` + reportId, options);
    } catch (e) {
        if (e.response) {
            const err = e.response.data.error;
            throw err || (e.response.status + ": " + e.response.statusText)
        }
    }

    return response;
}

export async function fetchUserReports() {
    const options = {
        headers: {
            'Authorization': `Bearer ${getJwtToken()}`,
            'Content-Type': 'application/json'
        },
    }

    let response;
    try {
        response = await axios.get(`${getApiURL()}/report/`, options);
    } catch (e) {
        if (e.response) {
            const err = e.response.data.error;
            throw err || (e.response.status + ": " + e.response.statusText)
        }
    }

    return response;
}

export async function fetchPatchFile(patchFileUrl){
    // if (getCurEnv() === 'local') {
    //     return new Promise((resolve, rej) => {
    //         resolve( "Mock Patch file content\n :D")
    //     }
    //     ).then(res => {
    //         return res;
    //     })
    // }
    let response;
    try {
        response = await axios.get(patchFileUrl);
        console.log(response);
    }
    catch (e) {
        console.log(e);
    }
    return response.data;
}