import { getApiURL } from '../../utils/envVarsReader';
import axios from "axios";
import { getJwtToken } from '../auth/Auth';
// import mockHistory from "./mockHistory.json"


/**
 * Fetch history from server.
 * @returns {Response} Response object returned from calling lambda
 */
export async function fetchHistory(cve, device) {
    // if (getCurEnv() === 'local') {
    //     return new Promise((resolve, rej) => {
    //         resolve({
    //             'data': mockHistory
    //         })
    //     }
    //     ).then(res => {
    //         return res;
    //     })
    // }
    const options = {
        headers: {
            'Authorization': `Bearer ${getJwtToken()}`,
            'Content-Type': 'application/json'
        },
    }

    let response;
    try {
        response = await axios.get(`${getApiURL()}/history?cve=${cve}&device=${encodeURIComponent(`${device}`)}`, options);
    } catch (e) {
        if (e.response) {
            const err = e.response.data.error;
            throw err || (e.response.status + ": " + e.response.statusText)
        }
    }

    return response;
}
