import { Badge, Box, Button, ColumnLayout, Container, ExpandableSection, Grid, Header, Multiselect, Pagination, Popover, SpaceBetween, Table } from "@amzn/awsui-components-react";
import { EmptyState, ValueWithLabel, deepCopy, isEmptyArr } from "../util";
import React, { memo } from "react";
import { gridDefinition } from "../../commons/layout";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { useDispatch } from "react-redux";
import { setSplitPanelData } from "../../features/splitPanel/splitPanelSlice";
import { baseColumns, groupBy, pannDecisionDetail } from "./reportDetailUtils";
import Breadcrumbs from "../../commons/breadcrumbs";
import { formatDate } from "../../features/report/dateConverter";

export const TicketDetail = ({ detail }) => {
    const tableSize = 20;
    let pos = 0;
    let cveDevice2Jiras = {};
    Object.keys(detail.jiraDeviceCvePairs).forEach(jira => {
        const devicesCveObj = detail.jiraDeviceCvePairs[jira];
        return (devicesCveObj.device || []).forEach(device => {
            const key = `${devicesCveObj.cveId}_${device}`;
            let val = cveDevice2Jiras[key] || []
            val.push(jira);
            cveDevice2Jiras[key] = val;
        })
    })
    console.log(cveDevice2Jiras);
    const totalEntries = detail.report ? detail.report.flatMap(item => 
        cveDevice2Jiras[`${item.cveId}_${item.device}`].map(jira => {
            return {
                "pos": pos += 1,
                "jira": jira,
                "cveId": item.cveId,
                "device": item.device,
                "pannDecision": item.pannDecision,
                "patchFound": item.patchFound,
                "mergeStatus": item.mergeStatus,
                "codeCompiled": item.codeCompiled,
                "confidence": item.confidence,
                "patchUrl2Ref": item.patchUrl && item.patchUrl.reduce((obj, url) => { return { ...obj, [url]: item.cvePatch[url] }; }, {}),
                "fileMap": item.fileMap,
                "associatedJira": item.jiras
            }
        })
    ) : [];
    console.log(totalEntries)

    const TicketOverview = ({ detail }) => {
        return <Container header={<Header variant="h2">Pann Analysis Overview</Header>}>
            <ColumnLayout columns={2}>
                <ValueWithLabel label="Report Type">{detail.reportType}</ValueWithLabel>
                <ValueWithLabel label="Scan Time">{formatDate(detail.timestamp)}</ValueWithLabel>
            </ColumnLayout>
            <ColumnLayout columns={2}>
                <ExpandableSection headerText={detail.jiraList.length + " Jiras Requested"}>
                    {isEmptyArr(detail.jiraList) ? "-" : detail.jiraList.sort().map(jira => <Badge key={jira} className={"device-badge"}>{jira}</Badge>)}
                </ExpandableSection>
                <ExpandableSection headerText={detail.jiraListInvalid.length + " Jiras Invalid"}>
                    {isEmptyArr(detail.jiraListInvalid) ? "-" : detail.jiraListInvalid.sort().map(jira => <Badge key={jira} color={"red"} className={"device-badge"}>{jira}</Badge>)}
                </ExpandableSection>
            </ColumnLayout>
            {detail.vendor &&
                <ColumnLayout columns={1}>
                    <ExpandableSection headerText={detail.jiraListWithoutPermission.length + " Jiras Without Permission"} defaultExpanded={!isEmptyArr(detail.jiraListWithoutPermission)}>
                        <SpaceBetween direction="horizontal" size="xxs">
                            {isEmptyArr(detail.jiraListWithoutPermission) ? "-" : detail.jiraListWithoutPermission.sort().map(jira => <Badge key={jira} color="red">{jira}</Badge>)}
                        </SpaceBetween>
                    </ExpandableSection>
                </ColumnLayout>
            }
        </Container>
    }

    const Details = memo(({ totalEntries }) => {

        const PannAnalysisExpandables = ({ analysis }) => {
            const [selectedKeys, setSelectedKeys] = React.useState([]);
            const [selectedDecisions, setSelectedDecisions] = React.useState([]);
            if (!analysis || Object.keys(analysis).length === 0) {
                return <EmptyState title="No data" />
            }
            return (
                <SpaceBetween size="m">
                    <Multiselect selectedOptions={selectedKeys.map((key) => { return { label: key, value: key } })}
                        onChange={({ detail }) =>
                            setSelectedKeys(detail.selectedOptions.map(opt => opt.value))}
                        filteringType="auto"
                        noMatch="No matching item"
                        options={Object.keys(analysis).sort().map(key => { return { label: key, value: key } })}
                        placeholder={`Filter by Tickets`}
                        empty="No options available"
                    />
                    <Multiselect selectedOptions={selectedDecisions.map((item) => { return { label: item, value: item } })}
                        onChange={({ detail }) => {
                            const selectedDecisions = detail.selectedOptions.map(opt => opt.value);
                            setSelectedDecisions(selectedDecisions);
                        }
                        }
                        filteringType="auto"
                        noMatch="No matching item"
                        options={[...new Set(totalEntries.filter(entry => entry.pannDecision).map(entry => entry.pannDecision))].map(decision => { return { label: decision, value: decision } })}
                        placeholder={`Filter by PANN Decisions`}
                        empty="No options available"
                    />
                    <ExpandableCompDetails analysis={analysis} selectedKeys={selectedKeys} selectedDecisions={selectedDecisions} />
                </SpaceBetween>
            )
        }

        const ExpandableCompDetails = memo(({ analysis, selectedKeys, selectedDecisions }) => {
            const [id2Expanded, setId2Expanded] = React.useState({})
            const [selectedItems, setSelectedItems] = React.useState([]);

            const columnDefinitions = [...baseColumns]
            columnDefinitions.unshift({
                id: "device",
                header: "Device",
                sortingField: "device",
                cell: item => item.device
            });
            columnDefinitions.unshift({
                id: "cveId",
                header: "CVE",
                sortingField: "cveId",
                cell: item => item.cveId
            });
            const decFilteredAnalysis = selectedDecisions.length > 0 ? Object.keys(analysis).reduce((obj, key) => {
                obj[key] = analysis[key].filter(item => selectedDecisions.includes(item.pannDecision))
                return obj;
            }, {}) : analysis
            return Object.keys(decFilteredAnalysis).sort((k1, k2) => decFilteredAnalysis[k2].length - decFilteredAnalysis[k1].length || k2.localeCompare(k1))
            .filter((key) => selectedKeys.length > 0 ? selectedKeys.includes(key) : true).map((key) => <PannExpandable key={key} id={key} tableEntries=
                {decFilteredAnalysis[key]} columnDefinitions={columnDefinitions} selectedItems={selectedItems} setSelectedItems={setSelectedItems} id2Expanded={id2Expanded} setId2Expanded={setId2Expanded} />
            )
        })


        const PannExpandable = memo(({ id, tableEntries, columnDefinitions, selectedItems, setSelectedItems, id2Expanded, setId2Expanded }) => {
            return <ExpandableSection
                key={id}
                headerText={id}
                headerDescription={`${tableEntries.length}-item`}
                onChange={({ detail }) => {
                    const copied = deepCopy(id2Expanded);
                    copied[id] = detail.expanded;
                    setId2Expanded(copied)
                }}
            >
                {id2Expanded[id] && <PannDetailTable tableEntries={tableEntries} columnDefinitions={columnDefinitions} selectedItems={selectedItems} setSelectedItems={setSelectedItems} />}
            </ExpandableSection>
        })

        const PannDetailTable = memo(({ tableEntries, columnDefinitions, selectedItems, setSelectedItems }) => {
            const { items, collectionProps, paginationProps } = useCollection(
                tableEntries,
                {
                    pagination: { pageSize: tableSize },
                    sorting: {
                        defaultState: {
                            sortingColumn: {
                                sortingField: "prediction",
                                sortingComparator: (a, b) => {
                                    if (a.prediction === undefined || a.prediction === null) {
                                        a.prediction = ""
                                    }
                                    if (b.prediction === undefined || b.prediction === null) {
                                        b.prediction = ""
                                    }
                                    return b.prediction.toString().localeCompare(a.prediction.toString()) || a.device.localeCompare(b.device) || a.cveId.localeCompare(b.cveId)
                                }
                            },
                        }
                    },
                }
            );
            console.log(tableEntries);
            const dispatch = useDispatch();
            return <Table
                onSelectionChange={({ detail }) => {
                    setSelectedItems(detail.selectedItems);
                    const item = detail.selectedItems[0];
                    dispatch(setSplitPanelData({
                        "patchUrl2Ref": item.patchUrl2Ref,
                        "fileMap": item.fileMap,
                        "headerTxt": `${item.cveId}/${item.device}`,
                        "showPatches": true,
                        "associatedJira": item.associatedJira,
                    }));
                }
                }
                selectedItems={selectedItems}
                ariaLabels={{
                    selectionGroupLabel: "Items selection",
                    itemSelectionLabel: ({ selectedItems }, item) =>
                        item.pos
                }}
                variant="borderless"
                {...collectionProps}
                columnDefinitions={columnDefinitions}
                items={items}
                loadingText="Loading resources"
                trackBy="pos"
                wrapLines
                selectionType="single"
                stickyHeader
                pagination={< Pagination {...paginationProps} ariaLabels="pagination" />}
                empty={
                    <EmptyState title={'No valid data'} />
                }
                header={<Header
                    actions={< SpaceBetween
                        direction="horizontal"
                        size="xs">
                        <Popover triggerType="custom" position="left" content={pannDecisionDetail}>
                            <Button iconName="status-info" variant="icon" />
                        </Popover>
                    </SpaceBetween>
                    }
                />}
            />
        })

        return <Container header={<Header variant="h2">Pann Analysis Details<Popover triggerType="custom" position="right" content={pannDecisionDetail}>
            <Button iconName="status-info" variant="icon" />
        </Popover></Header>}>
                 <PannAnalysisExpandables analysis={groupBy(totalEntries, "jira")} />
        </Container>
    })

    return <Box padding={{ vertical: 'xxl', horizontal: 's' }}>
        <Grid
            gridDefinition={[
                gridDefinition
            ]}
        >
            <SpaceBetween size="m">
                <Breadcrumbs isReportDetail={true} />
                <TicketOverview detail={detail} />
                <Details totalEntries={totalEntries} />
            </SpaceBetween>
        </Grid>
    </Box>
}