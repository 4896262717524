import { getCurEnv } from "../../utils/envVarsReader";
import { CognitoAuth } from "amazon-cognito-auth-js";
import { getAuthConfig } from "../../utils/Constants";

let JwtToken;
let username;
let userGroups = [];

const lastUrlKey = "lastUrl";
const expireTimeKey = "expireTime";
const interval = 10 * 1000;     // 10 sec


//User authentication and authorization
export const auth = async () => {
    const userAlias = await setTokenInfo();    // durantion is in sec
    if (userAlias) {
        setInterval(checkTimeAndGetNewToken, interval)    // time in milliseconds
    }
    return userAlias;
}


const checkTimeAndGetNewToken = () => {
    const expireTimestampInSec = localStorage.getItem(expireTimeKey);
    const timeNowInSec = Date.now() / 1000;  // ms to sec
    if (timeNowInSec >= expireTimestampInSec) {
        //console.log("token expired, auto renew");
        setTokenInfo(null, true);
    }
}


// set jwtToken and expireTime from auth object; get user alias
const setTokenInfo = async () => {
    // const logger = getLogger();
    console.log("inside setTokenInfo")
    let decodedAuthObj;
    try {
        let authObj;
        if (getCurEnv() === 'local') {
            decodedAuthObj = getDummyAuthObj();
            JwtToken = "dummyJWT"
            username = decodedAuthObj['custom:unique_name'];
        } else {
            authObj = await generateOrGetAuthObj();
            decodedAuthObj = authObj.decodePayload();

            if (decodedAuthObj) {
                if (decodedAuthObj['custom:unique_name']) {
                    username = decodedAuthObj['custom:unique_name'];
                }
                if (decodedAuthObj['custom:group']) {
                   try {
                       userGroups = JSON.parse(decodedAuthObj['custom:group']);
                   } catch (err) {}
                }
            }

            JwtToken = authObj.getJwtToken();

            localStorage.setItem(expireTimeKey, decodedAuthObj.exp)    // set expire time for the token
            console.log(authObj);
        }
        //logger.info(`get auth object: ${JSON.stringify(decodedAuthObj, null, 4)}`);
    } catch (e) {
        // only do the unauth flow if this is the first time trying to auth
        console.log(`get auth object failed: ${e}`);
        throw e;
    }

    return username;
}

export const getJwtToken = () => {
    return JwtToken;
}

export const getUserGroups = () => {
    return userGroups;
}

function redirectToOriginalPage() {
    // Replace the href because the Cognito passes the OAuth2 grant code in the query string and the grant code is not reusable
    if (window.history.length > 0) {
        window.history.replaceState(undefined, 'Scan Portal', localStorage.getItem(lastUrlKey));
    }
}

const generateOrGetAuthObj = () => {
    return new Promise((resolve, reject) => {
        const authConfig = getAuthConfig();
        const auth = new CognitoAuth(authConfig)

        auth.userhandler = {
            onFailure: (err) => {
                redirectToOriginalPage();
                reject(err);
            },
            onSuccess: (result) => {
                redirectToOriginalPage();
                resolve(auth.getSignInUserSession().getIdToken());
            }
        };

        auth.useCodeGrantFlow();

        const href = window.location.href;
        const session = auth.getSignInUserSession();

        if (session.isValid()) {
            resolve(session.getIdToken());
        } else if (href.indexOf('?code') > 0) {
            auth.parseCognitoWebResponse(href);     // triggers userhandler
        } else {
            auth.clearCachedTokensScopes();
            auth.getSession();
            localStorage.setItem(lastUrlKey, href);
        }
    });
}

const getDummyAuthObj = () => {
    return {
        "custom:unique_name": "test",
        "custom:group": [],
        identities: [
            {
                userId: "test"
            }
        ],
        iat: 0,
        exp: 10,        // in sec
    }
}