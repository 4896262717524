import * as React from "react";
import {
    ContentLayout, Flashbar
} from "@amzn/awsui-components-react";
import "./styles/portrait.css"
import "./styles/header.css"
import "./styles/file.css"
import { useDispatch, useSelector } from "react-redux";
import { preLoad } from "./preLoad";
import { selectUserAlias } from "./features/auth/userAliasSlice";
import { Route, createBrowserRouter, RouterProvider, createRoutesFromElements } from 'react-router-dom';
import { ScanPage } from "./pages/scanPage/scanPage";
import { MyHeader } from "./commons/myHeader";
import { ReportDetail } from "./pages/reportDetailPage/reportDetail";
import {ReportList} from "./pages/reportListPage/reportList";
import { InfoPage } from "./pages/infoPage/infoPage";


const AuthLoadingBar = () => {
    return <Flashbar
        items={[
            {
                type: "success",
                loading: true,
                content: "Authenticating and Authorizing"
            }
        ]}
    />;
}

function App() {
    const [error, setError] = React.useState(null);
    const dispatch = useDispatch();
    const userAlias = useSelector(selectUserAlias);
    React.useEffect(() => {
        preLoad(dispatch).catch(e => setError(e));
    }, [dispatch])

    if (error) {
        console.error(error);
    }

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route>
                < Route exact path="/myReport/:reportId" element={<ReportDetail />} />
                < Route exact path="/myReport" element={<ReportList />} />
                < Route exact path="/info" element={<InfoPage />} />
                {
                    ["/scan", "/"].map((path, index) => {
                        return (
                            <Route key={index} path={path} element={<ScanPage />} />
                        )
                    })
                }
                    </Route>

        )
    )

    return userAlias ? (
        <ContentLayout class="no-padding" header={<MyHeader />}>
            <RouterProvider router={router} />
        </ContentLayout>
    ) : <AuthLoadingBar />
}

export default App;
