import { getApiURL } from '../../utils/envVarsReader';
import axios from "axios";
import { getJwtToken } from '../auth/Auth';

export async function submitFeedback(payload) {
    const options = {
        headers: {
            'Authorization': `Bearer ${getJwtToken()}`,
            'Content-Type': 'application/json'
        },
    }

    let response;
    try {
        response = await axios.post(`${getApiURL()}/rating`, payload, options);
    } catch (e) {
        if (e.response) {
            const err = e.response.data.error;
            throw err || (e.response.status + ": " + e.response.statusText)
        }
    }

    return response;
}