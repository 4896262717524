import { Box, Button, ColumnLayout, FileUpload, Form, Link, Multiselect, SpaceBetween } from "@amzn/awsui-components-react";
import FormField from "@amzn/awsui-components-react/polaris/form-field";

import React from "react";
import { ValueWithLabel, getLabelsFromTokens } from "../util";
import {getDeviceObjsAll, getDeviceObjsOptions} from "../../features/device/devicesUtil";
import { createNewReport, uploadFileToS3 } from "../../features/scan/createReport";
import StatusBar from "../../commons/statusBar";
import { useNavigate } from "react-router-dom";

export function PatchDeviceTab({devices}) {
    const navigate = useNavigate();
    const [files, setFiles] = React.useState([]);
    const [fileName, setFileName] = React.useState("");
    const [selectedDevices, setSelectedDevices] = React.useState([])
    const [previousAll, setPreviousAll] = React.useState(false);
    const [submitBtnDisabled, setSubmitBtnDisabled] = React.useState(true)
    const [submitBtnLoading, setSubmitBtnLoading] = React.useState(false);
    const [statusMsgObj, setStatusMsgObj] = React.useState(null);

    const onSubmit = async () => {
        setSubmitBtnLoading(true);
        try {
            const res = await createNewReport({ devices: getLabelsFromTokens(selectedDevices), patchMode: true, fileName: fileName });
            await uploadFileToS3(res.data.presignedUrl, files[0])
        } catch (e) {
            console.log(e)
            setStatusMsgObj({ 'type': 'error', message: `Report creation failed: ${e}` })
            return;
        } finally {
            setSubmitBtnLoading(false);
        }
        setStatusMsgObj({
            'type': 'success', message: "Report created successfully.",
            action: <Link variant="primary" href="/myReport" onFollow={(event) => { event.preventDefault(); navigate("/myReport") }}><Button>View Report</Button></Link>
        })
    }

    return (        
        <Box>
            <SpaceBetween size="l">
                <StatusBar statusMsgObj={statusMsgObj} />
                <Box >Upload your Linux kernel patch file in <Link external href={'https://git-scm.com/docs/diff-format'}>git diff format</Link> , and PANN Dashboard will evaluate the applicability for your patch on selected Devices.</Box>
                <Form
                    actions={
                        <Button variant="primary" disabled={submitBtnDisabled} loading={submitBtnLoading} onClick={() => onSubmit()}>Submit</Button>
                    }
                >
                <ColumnLayout columns={2} variant="text-grid">
                        <FormField
                            label="Patch Diff File"
                            description="Upload the patch diff file"
                        >
                            <FileUpload
                                onChange={({ detail }) => {
                                    console.log(detail.value)
                                    setFiles(detail.value);
                                    if (detail.value.length > 0) {
                                        setFileName(detail.value[0].name);
                                    }
                                    setSubmitBtnDisabled(detail.value.length === 0 || selectedDevices.length === 0);
                                }
}
                                value={files}
                                i18nStrings={{
                                    uploadButtonText: e =>
                                        e ? "Choose files" : "Choose file",
                                    dropzoneText: e =>
                                        e
                                            ? "Drop files to upload"
                                            : "Drop file to upload",
                                    removeFileAriaLabel: e =>
                                        `Remove file ${e + 1}`,
                                    limitShowFewer: "Show fewer files",
                                    limitShowMore: "Show more files",
                                    errorIconAriaLabel: "Error"
                                }}
                                showFileLastModified
                                showFileSize
                                showFileThumbnail
                            />
                        </FormField>
                        <ValueWithLabel label="Devices">
                            <Multiselect
                                selectedOptions={selectedDevices}
                                onChange={({ detail }) => {
                                    let selectedOptions = detail.selectedOptions;
                                    setSubmitBtnDisabled(selectedOptions.length === 0)
                                    // find any item with "all" value
                                    let allIndex = selectedOptions.findIndex(item => item.value === "*");
                                    let currentAllSelected = allIndex > -1;

                                    if (previousAll) {
                                        if (currentAllSelected) {
                                            if (selectedOptions.length < getDeviceObjsAll(devices).length + 1) {
                                                setSelectedDevices(selectedOptions.filter(item => item.value !== "*"));
                                                setPreviousAll(false);
                                            } else {
                                                setSelectedDevices([{label: "All", value: "*"}, ...getDeviceObjsAll(devices)]);
                                                setPreviousAll(true);
                                            }
                                        } else {
                                            setSelectedDevices([])
                                            setPreviousAll(false);
                                        }
                                    } else {
                                        if (currentAllSelected) {
                                            setSelectedDevices([{label: "All", value: "*"}, ...getDeviceObjsAll(devices)]);
                                            setPreviousAll(true);
                                        } else {
                                            if (selectedOptions.length === getDeviceObjsAll(devices).length) {
                                                setSelectedDevices([{label: "All", value: "*"}, ...getDeviceObjsAll(devices)]);
                                                setPreviousAll(true);
                                            }
                                            else {
                                                setSelectedDevices(selectedOptions);
                                            }
                                        }
                                    }
                                    setSubmitBtnDisabled(files.length === 0 || detail.selectedOptions.length === 0);
                                }}
                                options={[{label: "All", value: "*"}, ...getDeviceObjsOptions(devices)]}
                                statusType={devices ? (devices.length > 0 ? "finished" : "loading") : "error"}
                                errorText="Unable to fetch devices"
                                loadingText="Fetching devices"
                                filteringType="auto"
                                placeholder="Choose devices"
                            />
                        </ValueWithLabel>
                </ColumnLayout>

                </Form>
        </SpaceBetween>
        </Box>
    );
    
}