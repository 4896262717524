import React from 'react';
import { SideNavigation } from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom'
const items = [
  {
    type: 'link',
    text: 'Info',
    href: '/info'
  },
  {
    type: 'link',
    text: 'Scan',
    href: '/scan'
  },
  {
    type: 'link',
    text: 'My Reports',
    href: '/myReport'
  },
];


export function Navigation(props) {
  const navigate = useNavigate();

  return (
    <SideNavigation
      items={items}
      activeHref={props.activeHref}
      onFollow={(event) => {
        event.preventDefault();
        navigate(event.detail.href);
      }
      }
    />
  );
}