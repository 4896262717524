import { SpaceBetween } from "@amzn/awsui-components-react";
import { ValueWithLabel } from "../util";
import "../../styles/report.css"

export function groupBy(totalEntries, fieldName) {
    
    return totalEntries.reduce((groups, item) => {
        const group = (groups[item[fieldName]] || []);
        group.push(item);
        groups[item[fieldName]] = group;
        return groups;
    }, {})
} 


export function getNumOfApplicables(analysis) {
    return analysis.reduce((acc, item) => item.pannDecision === "ReadyToApply" ? acc + 1 : acc, 0)
}

const pannDecision2BgColor = (pannDecision) => {
    if (pannDecision === "ReadyToApply") {
        return "#00FF00"
    } else if (pannDecision === "CannotApply") {
        return "#FF0000"
    } else if (pannDecision === "ComponentDisabled" || pannDecision === "PANNError") {
        return "#FFA500"
    } else if (pannDecision === "BackportedPatchNotFound" || pannDecision === "VersionMismatch" || pannDecision === "CVERejected" || pannDecision === "CVEReserved") {
        return "#FFFF00"
    } else if (pannDecision === "AlreadyFixed") {
        return "#F5F5DC"
    } else if (pannDecision === "Fixed&ExtraPatchFound") {
        return "#AFEEEE"
    } else if (pannDecision === "PatchUnavailable" || pannDecision === "PANNUnsupported") {
        return "#636060"
    } else {
        return ""
    }
}

const pannDecision2Color = (pannDecision) => {
    if (pannDecision === "CannotApply" || pannDecision === "PatchUnavailable" || pannDecision === "PANNUnsupported") {
        return "white"
    } else {
        return "black"
    }
}
export const baseColumns = [
    {
        id: "pannDecision",
        header: "PANN Decision",
        sortingField: "pannDecision",
        cell: item => item.pannDecision ? <span className="my-badge" style={{ color: pannDecision2Color(item.pannDecision), backgroundColor: pannDecision2BgColor(item.pannDecision) }}>{item.pannDecision}</span> : "-"
    },
    {
        id: "confidence",
        header: "Confidence",
        sortingField: "confidence",
        cell: item => item.confidence === undefined ? "-" : `${((item.confidence) * 100).toFixed(2)}%`
    },

]

export const pannDecisionDetail = <SpaceBetween size="xs">
    <ValueWithLabel label={"ReadyToApply"}>PANN predicts the patch is applicable, and no other exclusivity criteria are met.</ValueWithLabel>
    <ValueWithLabel label={"CannotApply"}>PANN predicts the patch is not applicable, but the vulnerability related files compile.</ValueWithLabel>
    <ValueWithLabel label={"ComponentDisabled"}>The relevant code component for the patch is disabled or not enabled, irrespective of CVE status or patch availability.</ValueWithLabel>
    <ValueWithLabel label={"VersionMismatch"}>The patch version is incompatible with the device’s current configuration, independent of other patch or CVE statuses.</ValueWithLabel>
    <ValueWithLabel label={"BackportedPatchNotFound"}>For this package version, no compatible patch found and the vulnerability is still reported affected</ValueWithLabel>
    <ValueWithLabel label={"AlreadyFixed"}>The patch for the CVE is already integrated into the codebase. This takes precedence over other conditions (except for ComponentDisabled).</ValueWithLabel>
    <ValueWithLabel label={"PatchUnavailable"}>No patch is found for the CVE or patch is invalid formatted, exclusive of the patch’s applicability or device’s compatibility.</ValueWithLabel>
    <ValueWithLabel label={"Fix&ExtraPatchNeeded"}>A previous patch has been applied, but a new patch is needed, distinct from current patch applicability or CVE status.</ValueWithLabel>
    <ValueWithLabel label={"CVERejected"}>CVE is marked as rejected by NVD.</ValueWithLabel>
    <ValueWithLabel label={"CVEReserved"}>CVE is marked as reserved by NVD.</ValueWithLabel>
    <ValueWithLabel label={"PANNUnsupported"}>Input CVE/Jira is not valid or not supported by PANN.</ValueWithLabel>
</SpaceBetween>


const fields2Filter = ["cveId", "device", "pannDecision", "patchUrl", "fileMap"]

export const filterPannDetail = (filteringText, setFilteringText, setFilteredEntries, tableEntries) => {
    if (!filteringText === null || filteringText.length === 0) {
        setFilteredEntries(tableEntries);
    }
    else {
        const filteredEntries = tableEntries.filter(entry => {
            return fields2Filter.reduce((res, field) => {
                const val = entry[field];
                return res || (val && val.toString().toLowerCase().includes(filteringText))
            }, false);
        })
        setFilteredEntries(filteredEntries);
    }

    setFilteringText(filteringText);
}