import { useEffect, useState } from "react";
import { Box, Button, Header, Modal, SpaceBetween, StatusIndicator, Textarea } from "@amzn/awsui-components-react";
import { Rating } from 'react-simple-star-rating';
import { submitFeedback } from "../../features/report/submitFeedback";

export const Feedback = ({ reportId, visible, setVisible, showFeedbackLater, setFeedbackSumitted }) => {
    const maxStars = 5;
    const [numOfStars, setNumOfStars] = useState(maxStars);
    const [comment, setComment] = useState("");
    const [statusMsgObj, setStatusMsgObj] = useState(null);
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

    // reset visible if it changes
    useEffect(() => {
        setVisible(visible)
    }, [visible, setVisible])

    const onSubmit = async() => {
        if (comment.replace(' ', '').length === 0 && numOfStars < maxStars) {
            setStatusMsgObj(<StatusIndicator type="warning">Please provide comment</StatusIndicator>)
            return;
        }
        let res;
        setSubmitBtnLoading(true);
        try {
            res = await submitFeedback({
                "stars": numOfStars,
                "reportId": reportId,
                "comment": comment
            });
            console.log(res)
        } catch (e) {
            const errMsg = `Fedback submit failed: ${e}`
            console.error(errMsg);
            setStatusMsgObj(<StatusIndicator type="error">{errMsg}</StatusIndicator>);
            setSubmitBtnLoading(false);
            return;
        }
        setStatusMsgObj(<StatusIndicator>Success</StatusIndicator>)
        setFeedbackSumitted(true)
        setTimeout(() => setVisible(false), 2000);
    }

    return (
        <Box>
            <Modal
            onDismiss={({detail}) => {
                console.log(detail.reason)
                if (detail.reason!=="overlay") {    // prevent clicking outside to close the modal
                    setVisible(false);
                }
            }}
            visible={visible}
            header={`Feedback`}
            size="medium"
        >
            <SpaceBetween size="m">
                <Box>
                    <Header variant="h4">How useful is this report?</Header>
                    <Rating
                        name="starRating"
                        initialValue={maxStars}
                        iconsCount={maxStars}
                        onClick={(value, index, event) => {setNumOfStars(value)}}
                    />
                </Box>
                <Box>
                    <Header variant="h4">Why would you provide this rating?</Header>
                    <Textarea spellcheck value={comment} onChange={({detail}) => setComment(detail.value)}/>
                </Box>
                {statusMsgObj}
                <SpaceBetween direction="horizontal" size="s">
                        <Button variant="primary" loading={submitBtnLoading} onClick={() => onSubmit()}>Submit</Button>
                    <Button onClick={() => {
                        setVisible(false);
                        showFeedbackLater();
                    }}>Need more time</Button>

            </SpaceBetween>
        </SpaceBetween>    
    </Modal>
    </Box>
    )
}