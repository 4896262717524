import { Badge, Box, Button, ColumnLayout, Container, ExpandableSection, Grid, Header, Multiselect, Pagination, Popover, SpaceBetween, Table, Tiles } from "@amzn/awsui-components-react";
import { EmptyState, ValueWithLabel, deepCopy, isEmptyArr } from "../util";
import React, { memo } from "react";
import { gridDefinition } from "../../commons/layout";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { useDispatch } from "react-redux";
import { setSplitPanelData } from "../../features/splitPanel/splitPanelSlice";
import { baseColumns, groupBy, pannDecisionDetail} from "./reportDetailUtils";
import Breadcrumbs from "../../commons/breadcrumbs";
import { formatDate } from "../../features/report/dateConverter";


export const CveDeviceDetail = memo(({detail}) => {
    const tableSize = 20;
    let pos = 0;
    const totalEntries = detail.report.map(item => {
        return {
            "pos": pos += 1,
            "cveId": item.cveId,
            "device": item.device,
            "pannDecision": item.pannDecision,
            "patchFound": item.patchFound,
            "mergeStatus": item.mergeStatus,
            "codeCompiled": item.codeCompiled,
            "confidence": item.confidence,
            "patchUrl2Ref": item.patchUrl && item.patchUrl.reduce((obj, url) => { return { ...obj, [url]: item.cvePatch[url] }; }, {}),
            "fileMap": item.fileMap,
            "associatedJira": item.jiras,
        }
    });

    // const CveDeviceTable = memo(({detail}) => {
    //     let pos = 0;
    //     const tableEntries = detail.cveList.flatMap(cve => detail.devices.map(device => {
    //         const analysis = detail.report[cve][device];
    //         return {
    //             "pos": pos += 1,
    //             "cve": cve,
    //             "device": device,
    //             "prediction": analysis.prediction,
    //             "patchFound": detail.report[cve].patchFound,
    //             "mergeStatus": analysis.mergeStatus,
    //             "codeCompiled": analysis.codeCompiled,
    //             "patchUrl": analysis.patchUrl,
    //             "fileMap": analysis.fileMap,
    //         }
    //     }));

    //     const { items, collectionProps, paginationProps } = useCollection(
    //         tableEntries,
    //         {
    //             pagination: { pageSize: tableSize },
    //             sorting: {},
    //         }
    //     );

    //     console.log(tableEntries)
    //     const [selectedItems, setSelectedItems] = React.useState([]);
    //     const dispatch = useDispatch();
    //     return <Table
    //         onSelectionChange={({ detail }) => {
    //             setSelectedItems(detail.selectedItems);
    //             const item = detail.selectedItems[0];
    //             dispatch(setSplitPanelData({
    //                 "patchUrl": item.patchUrl,
    //                 "fileMap": item.fileMap,
    //                 "headerTxt": `${item.cve}/${item.device}`,
    //                 "showPatches": true
    //             }));
    //         }
    //         }
    //         selectedItems={selectedItems}
    //         ariaLabels={{
    //             selectionGroupLabel: "Items selection",
    //             itemSelectionLabel: ({ selectedItems }, item) =>
    //                 item.pos
    //         }}
    //         columnDefinitions={[
    //             {
    //                 id: "cve",
    //                 header: "CVE",
    //                 sortingField: "cve",
    //                 cell: item => item.cve,
    //             },
    //             {
    //                 id: "device",
    //                 header: "Device",
    //                 sortingField: "device",
    //                 cell: item => item.device
    //             },
    //             {
    //                 id: "prediction",
    //                 header: "PANN Prediction (Patch Applicablity)",
    //                 sortingField: "prediction",
    //                 cell: item => <Badge color={item.prediction ? "red" : "green"}>{item.prediction ? "Applicable" : "Not Applicable"}</Badge>
    //             },
    //             {
    //                 id: "patchFound",
    //                 header: "Patch Found",
    //                 sortingField: "patchFound",
    //                 cell: item => item.patchFound === undefined ? "-" : item.patchFound.toString()
    //             },
    //             {
    //                 id: "mergeStatus",
    //                 header: "Merge Status",
    //                 sortingField: "mergeStatus",
    //                 cell: item => item.mergeStatus === undefined ? "-" : item.mergeStatus.toString()
    //             },
    //             {
    //                 id: "codeCompiled",
    //                 header: "Code Compiled",
    //                 sortingField: "codeCompiled",
    //                 cell: item => item.codeCompiled === undefined ? "-" : item.codeCompiled.toString()
    //             },
    //         ]}
    //         {...collectionProps}
    //         items={items}
    //         loadingText="Loading resources"
    //         trackBy="pos"
    //         resizableColumns
    //         wrapLines
    //         selectionType="single"
    //         stickyHeader
    //         pagination={< Pagination {...paginationProps} ariaLabels="pagination" />}
    //         empty={
    //             <EmptyState title={'No valid data'} />
    //         }
    //         header={<Header variant="h3">PANN Analysis Detail {getItemCntBadge(tableEntries.length)}</Header>}
    //     />
    // }, (prev, cur) => prev && cur && (prev.reportId === cur.reportId));


    const CveDeviceOverview = memo(({ detail }) => {
        return <Container header={<Header variant="h2">Pann Analysis Overview</Header>}>
                <SpaceBetween size="s">
                    <ColumnLayout columns={2}>
                        <ValueWithLabel label="Report Type">{detail.reportType}</ValueWithLabel>
                    <ValueWithLabel label="Scan Time">{formatDate(detail.timestamp)}</ValueWithLabel>
                    </ColumnLayout>
                    <ColumnLayout columns={2}>
                        <ExpandableSection
                            headerText={detail.cveList.length + " CVEs"}>
                            {isEmptyArr(detail.cveList) ? "-" : [...detail.cveList].sort().map(cve => <Badge key={cve} className={"device-badge"}>{cve}</Badge>)}
                        </ExpandableSection>
                        <ExpandableSection headerText={detail.devices.length + " Devices"}>
                            {isEmptyArr(detail.devices) ? "-" : [...detail.devices].sort().map(device => <Badge key={device} className={"device-badge"}>{device}</Badge>)}
                        </ExpandableSection>
                    </ColumnLayout>
                </SpaceBetween>
        </Container>
    })

    const Details = memo(({totalEntries}) => {

        const PannAnalysisExpandables = ({ analysis, type }) => {
            const [selectedKeys, setSelectedKeys] = React.useState([]);
            const [selectedDecisions, setSelectedDecisions] = React.useState([]);
            if (!analysis || Object.keys(analysis).length === 0) {
                return <EmptyState title="No data" />
            }
            return (
                <Box>
                    <SpaceBetween size="m">
                        <Multiselect selectedOptions={selectedKeys.map((key) => { return { label: key, value: key }})}
                            onChange={({ detail }) =>
                                setSelectedKeys(detail.selectedOptions.map(opt => opt.value))}
                            filteringType="auto"
                            noMatch="No matching item"
                            options={Object.keys(analysis).sort().map(key => { return { label: key, value: key } })}
                            placeholder={`Filter by ${type}s`}
                            empty="No options available"
                        />
                        <Multiselect selectedOptions={selectedDecisions.map((item) => { return { label: item, value: item } })}
                            onChange={({ detail }) => {
                                const selectedDecisions = detail.selectedOptions.map(opt => opt.value);
                                setSelectedDecisions(selectedDecisions);
                            }
                            }
                            filteringType="auto"
                            noMatch="No matching item"
                            options={[...new Set(totalEntries.filter(entry => entry.pannDecision).map(entry => entry.pannDecision))].map(decision => { return { label: decision, value: decision } })}
                            placeholder={`Filter by PANN Decisions`}
                            empty="No options available"
                        />
                        <ExpandableCompDetails analysis={analysis} type={type} selectedKeys={selectedKeys} selectedDecisions={selectedDecisions}/>
                    </SpaceBetween>
                </Box>
            )
        }

        const ExpandableCompDetails = memo(({ analysis, type, selectedKeys, selectedDecisions }) => {
            const [id2Expanded, setId2Expanded] = React.useState({})
            const [selectedItems, setSelectedItems] = React.useState([]);
            const columnDefinitions = [
                {
                    id: "device",
                    header: "Device",
                    sortingField: "device",
                    cell: item => item.device
                },
                {
                    id: "cveId",
                    header: "CVE",
                    sortingField: "cveId",
                    cell: item => item.cveId
                },
                ...baseColumns];
            const decFilteredAnalysis = selectedDecisions.length > 0 ? Object.keys(analysis).reduce((obj, key) => {
                    obj[key] = analysis[key].filter(item => selectedDecisions.includes(item.pannDecision))
                    return obj;
                }, {}) : analysis
            return <Box>
                {Object.keys(decFilteredAnalysis).sort((k1, k2) => decFilteredAnalysis[k2].length - decFilteredAnalysis[k1].length || k2.localeCompare(k1))
                .filter((key) => selectedKeys.length > 0 ? selectedKeys.includes(key) : true).map((key) => <PannExpandable key={key} id={key} tableEntries=
                    {decFilteredAnalysis[key]} columnDefinitions={columnDefinitions} selectedItems={selectedItems} setSelectedItems={setSelectedItems} id2Expanded={id2Expanded} setId2Expanded={setId2Expanded} />)}
            </Box>
        })


        const PannExpandable = memo(({ id, tableEntries, columnDefinitions, selectedItems, setSelectedItems, id2Expanded, setId2Expanded }) => {
            return <ExpandableSection
                key={id}
                headerText={id}
                headerDescription={`${tableEntries.length}-items`}
                onChange={({ detail }) => {
                    const copied = deepCopy(id2Expanded);
                    copied[id] = detail.expanded;
                    setId2Expanded(copied)
                }}
            >
                {id2Expanded[id] && <PannDetailTable tableEntries={tableEntries} columnDefinitions={columnDefinitions} selectedItems={selectedItems} setSelectedItems={setSelectedItems} />}
            </ExpandableSection>
        })

        const PannDetailTable = memo(({ tableEntries, columnDefinitions, selectedItems, setSelectedItems }) => {
            const { items, collectionProps, paginationProps } = useCollection(
                tableEntries,
                {
                    pagination: { pageSize: tableSize },
                    sorting: {
                        defaultState: {
                            sortingColumn: {
                                sortingField: "prediction",
                                sortingComparator: (a, b) => {
                                    if (a.prediction === undefined || a.prediction === null) {
                                        a.prediction = ""
                                    }
                                    if (b.prediction === undefined || b.prediction === null) {
                                        b.prediction = ""
                                    }
                                    return b.prediction.toString().localeCompare(a.prediction.toString()) || a.device.localeCompare(b.device) || a.cveId.localeCompare(b.cveId)
                                }
                            },
                        }
                    },
                }
            );
            const dispatch = useDispatch();
            return <Table
                onSelectionChange={({ detail }) => {
                    setSelectedItems(detail.selectedItems);
                    const item = detail.selectedItems[0];
                    dispatch(setSplitPanelData({
                        "patchUrl2Ref": item.patchUrl2Ref,
                        "fileMap": item.fileMap,
                        "headerTxt": `${item.cveId}/${item.device}`,
                        "showPatches": true,
                        "associatedJira": item.associatedJira
                    }));
                }
                }
                selectedItems={selectedItems}
                ariaLabels={{
                    selectionGroupLabel: "Items selection",
                    itemSelectionLabel: ({ selectedItems }, item) =>
                        item.pos
                }}
                variant="borderless"
                {...collectionProps}
                columnDefinitions={columnDefinitions}
                items={items}
                loadingText="Loading resources"
                trackBy="pos"
                wrapLines
                selectionType="single"
                stickyHeader
                pagination={< Pagination {...paginationProps} ariaLabels="pagination" />}
                empty={
                    <EmptyState title={'No valid data'} />
                }
            />
        })

        const [tileValue, setTileValue] = React.useState("CVE");
        return <Container header={<Header variant="h2">Pann Analysis Details<Popover triggerType="custom" position="right" content={pannDecisionDetail}>
                        <Button iconName="status-info" variant="icon" />
                    </Popover></Header>}>
                <Tiles
                    onChange={({ detail }) => setTileValue(detail.value)}
                    value={tileValue}
                    items={[
                        { label: "Group by CVE", value: "CVE" },
                        { label: "Group by Device", value: "Device" },
                    ]}
                />
                <br/>
                {(() => {
                    if (tileValue === "CVE") {
                        return <PannAnalysisExpandables analysis={groupBy(totalEntries, "cveId")} type={tileValue} />
                    } else if (tileValue === "Device") {
                        return <PannAnalysisExpandables analysis={groupBy(totalEntries, "device")} type={tileValue} />
                    } else {
                        return null;
                    }
                })()}
            </Container>
    })


    return <Box padding={{ vertical: 'xxl', horizontal: 's' }}>
        <Grid
            gridDefinition={[
                gridDefinition
            ]}
        >
            <SpaceBetween size="m">
                <Breadcrumbs isReportDetail={true}/>
                <CveDeviceOverview detail={detail} />
                <Details totalEntries={totalEntries}/>
            </SpaceBetween>
        </Grid>
    </Box>
})