import { createSlice } from "@reduxjs/toolkit";

const splitPanelDataSlice = createSlice({
    name: 'splitPanelData',
    initialState: null,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setSplitPanelData: (state, action) => {
            console.log(action)
            return action.payload
        }
    }
});

export const { setSplitPanelData } = splitPanelDataSlice.actions;

export const selectSplitPanelData = (state) => state.splitPanelData;

export default splitPanelDataSlice.reducer;