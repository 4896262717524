import React, {useState} from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
    Box,
    Button,
    CollectionPreferences,
    Header,
    Pagination, SpaceBetween,
    Table,
    TextFilter,
} from '@amzn/awsui-components-react';
import { columnDefinitions, getMatchesCountText, paginationLabels, collectionPreferencesProps } from './tableConfig';

function EmptyState({ title, subtitle, action }) {
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {title}
            </Box>
            <Box variant="p" padding={{ bottom: 's' }} color="inherit">
                {subtitle}
            </Box>
            {action}
        </Box>
    );
}

export default function ReportListTable({detail}) {
    const [preferences, setPreferences] = useState({ pageSize: 15, visibleContent: ['id', 'cveList', 'state', 'devices', 'timestamp', 'fileName', 'jiraIds', 'viewReport'] });
    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        detail,
        {
            filtering: {
                empty: <EmptyState title="No Reports" />,
                noMatch: (
                    <EmptyState
                        title="No matches"
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                ),
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {},
            selection: {},
        }
    );

    return (<SpaceBetween size="m">
                <Table
                    {...collectionProps}
                    // resizableColumns
                    resizableColumns

                    header={
                        <Header
                            counter={`(${detail.length})`}
                        >
                            Reports
                        </Header>
                    }
                    columnDefinitions={columnDefinitions}
                    visibleColumns={preferences.visibleContent}
                    items={items}
                    pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
                    filter={
                        <TextFilter
                            {...filterProps}
                            countText={getMatchesCountText(filteredItemsCount)}
                            filteringAriaLabel="Filter Reports"
                        />
                    }
                    preferences={
                        <CollectionPreferences
                            {...collectionPreferencesProps}
                            preferences={preferences}
                            onConfirm={({ detail }) => setPreferences(detail)}
                        />
                    }
                />
            </SpaceBetween>
    );
}
