import { createSlice } from "@reduxjs/toolkit";

const userAliasSlice = createSlice({
    name: 'userAlias',
    initialState: "",
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setUserAlias: (state, action) => {
            return action.payload
        }
    }
});

export const { setUserAlias } = userAliasSlice.actions;

export const selectUserAlias = (state) => state.userAlias;

export default userAliasSlice.reducer;