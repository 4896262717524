let deviceObjsOptions;

export const getDeviceObjsAll = (deviceObjs) => {
    if (!deviceObjs || deviceObjs.length === 0) {
        return [];
    }
    return deviceObjs.map(obj => {
        return {
            label: obj.device,
            value: obj.device,
        }
    });
}

export const getDeviceObjsOptions = (deviceObjs) => {
    if (!deviceObjs || deviceObjs.length === 0) {
        return [];
    }

    if (!deviceObjsOptions) {
        const deviceFamily2Device = deviceObjs.reduce((res, obj) => {
            res[obj.deviceFamily] = res[obj.deviceFamily] || [];
            res[obj.deviceFamily].push(obj.device);
            return res;
        }, Object.create(null));
        console.log(deviceFamily2Device)
        deviceObjsOptions = Object.keys(deviceFamily2Device).map(deviceFamily => {
            return {
                label: deviceFamily.toUpperCase(),
                options: deviceFamily2Device[deviceFamily].map(device => {
                        return {
                            label: device,
                            value: device,
                        }
                    })
            }
        });
        console.log(deviceObjsOptions)

    }
    return deviceObjsOptions;
}