import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {readEnvVars} from "./utils/envVarsReader";
import { Provider } from 'react-redux';
import { store } from './app/store';


readEnvVars().then(() => {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        </React.StrictMode>
    )
});
