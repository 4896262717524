let curEnv;
let apiURL;

export const readEnvVars = async () => {
    let response;
    const isLocalEnv = window.location.hostname === 'localhost';
    if (isLocalEnv) {
        setDummyEnvVars();
        return;
    }
    try {
        response = await fetch('/settings.json');
    } catch (e) {
        console.log(e);
        return;
    }
    response = await response.json();
    apiURL = response.apiURL;
    curEnv = response.env;
}

const setDummyEnvVars = () => {
    curEnv = 'local';
    apiURL = "";
}

export function getCurEnv () {
    return curEnv;
}

export function getApiURL() {
    return apiURL;
}