import { configureStore } from '@reduxjs/toolkit';
import userAliasReducer from '../features/auth/userAliasSlice';
import splitPanelDataReducer from '../features/splitPanel/splitPanelSlice';
import { getCurEnv } from '../utils/envVarsReader';

export const store = configureStore({
    reducer: {
        userAlias: userAliasReducer,
        splitPanelData: splitPanelDataReducer,
    },
    devTools: getCurEnv() !== 'prod',  // disable devTools so redux state in prod so state won't be modified
});