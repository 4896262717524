import React, {useEffect} from "react";
import StatusBar from "../../commons/statusBar";
import {AppLayout, Box, Grid, Spinner} from "@amzn/awsui-components-react";
import { Navigation } from "../../commons/navBar";
import {fetchUserReports} from "../../features/report/fetchReport";
import ReportListTable from "./reportListTable";
import Breadcrumbs from "../../commons/breadcrumbs";
import { gridDefinition } from "../../commons/layout";



export function ReportList() {
    const [statusMsgObj, setStatusMsgObj] = React.useState(null);
    const [content, setContent] = React.useState(<Box textAlign="center"> <Spinner size="large" /></Box>);


    useEffect(() => {

        function getContent(reportList) {
            if (reportList) {
                return <Box padding={{ vertical: 'xxl', horizontal: 's' }}>
                    <Grid
                        gridDefinition={[
                            gridDefinition
                        ]}
                    >
                <Breadcrumbs />
                <br />
                <ReportListTable detail={reportList} />
                    </Grid>
                </Box>
            }
        }

        async function fetchAndSetContent() {
            let res;
            try {
                res = await fetchUserReports();
            } catch (e) {
                const errMsg = `Cannot fetch the Reports. Please try again later.`
                console.error(errMsg);
                setStatusMsgObj({ type: "error", message: errMsg });
                return;
            }
            setContent(getContent(res.data.reports));
        }
        fetchAndSetContent();

    }, []);

    const [navigationOpen, setNavigationOpen] = React.useState(true);

    return (
        <AppLayout
            headerSelector="#h"
            disableContentPaddings={true}
            content={ statusMsgObj ? <StatusBar statusMsgObj={statusMsgObj}/> : content}
            navigation={<Navigation activeHref="/myReport" />}
            navigationOpen={navigationOpen}
            navigationWidth={200}
            onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
            toolsHide={true}
        />
    );
}