import React, { useEffect, useState } from "react";
import { ValueWithLabel, isEmptyArr, isEmptyObj } from "../pages/util";
import { Box, Button, Header, Link, SpaceBetween, SplitPanel } from "@amzn/awsui-components-react";
import { useSelector } from "react-redux";
import { selectSplitPanelData } from "../features/splitPanel/splitPanelSlice";
import { History } from "../pages/reportDetailPage/history";

export const ReportSplitPanel = () => {
    const splitPanelData = useSelector(selectSplitPanelData);
    const [historyVisible, setHistoryVisible] = React.useState(false);
    if (splitPanelData) {
        return <SplitPanel header={<Header variant="h4">{splitPanelData.headerTxt}</Header> } hidePreferencesButton>
                <ValueWithLabel label="Files location">
                    {isEmptyArr(splitPanelData.fileMap) ? "-" : <ul>{splitPanelData.fileMap.map(file => <li><Box key={file}>{file}</Box></li>)}</ul>}
                </ValueWithLabel>
            {splitPanelData.showPatches ?
                <Box >
                    <br /><hr /><br />
                    <ValueWithLabel label="Patch Urls with source (Beta: Check them before use)">
                        {isEmptyObj(splitPanelData.patchUrl2Ref) ? "-" : 
                        <ul>{Object.entries(splitPanelData.patchUrl2Ref).map(([url, source]) =>
                                <SpaceBetween size="s" key={url}>
                                    <li><Link target={"_blank"} href={url}>{source.hexsha}</Link> {source && <Link target={"_blank"} href={source.src}>[src]</Link>}</li>
                                </SpaceBetween>
                                )}</ul>
                || "-"}
                    </ValueWithLabel>
                </Box>
                : <Box/>
            }
            {splitPanelData.showPatches ?
                <Box >
                    <br /><hr /><br />
                    <ValueWithLabel label="Jira tickets associated">
                        {isEmptyObj(splitPanelData.associatedJira) ? "-" :
                            <ul>{Object.entries(splitPanelData.associatedJira).map(([id, jiraId]) =>
                                <SpaceBetween size="s" key={id}>
                                    <li><Link target={"_blank"} href={"https://issues.labcollab.net/browse/"+jiraId}>{jiraId}</Link></li>
                                </SpaceBetween>
                            )}</ul>
                            || "-"}
                    </ValueWithLabel>
                </Box>
                : <Box/>
            }
            {splitPanelData.showPatches ? 
                <Box >
                    {historyVisible && <History visible={historyVisible} setVisible={setHistoryVisible} cve={splitPanelData.headerTxt.split("/")[0]} 
                        device={splitPanelData.headerTxt.includes("/") && splitPanelData.headerTxt.split("/")[1]} />}
                    <br /><hr /><br />
                    <Button onClick={() => { setHistoryVisible(true) }}>View History</Button>
                </Box> : <Box />
            }
        </SplitPanel>
    } else {
        return <Header variant="h3">Select an item to view its details</Header>
    }
}

export const useSplitPanel = splitPanelData => {
    const [splitPanelSize, setSplitPanelSize] = useState(300);
    const [splitPanelOpen, setSplitPanelOpen] = useState(false);
    const [hasManuallyClosedOnce, setHasManuallyClosedOnce] = useState(false);

    const onSplitPanelResize = ({ detail: { size } }) => {
        setSplitPanelSize(size);
    };

    const onSplitPanelToggle = ({ detail: { open } }) => {
        setSplitPanelOpen(open);

        if (!open) {
            setHasManuallyClosedOnce(true);
        }
    };

    useEffect(() => {
        if (splitPanelData && !hasManuallyClosedOnce) {
            setSplitPanelOpen(true);
        }
    }, [splitPanelData, hasManuallyClosedOnce]);

    return {
        splitPanelOpen,
        onSplitPanelToggle,
        splitPanelSize,
        onSplitPanelResize,
    };
};