//Cognito config for test environment
import { getCurEnv } from "./envVarsReader";

const testAuthConfig = {
    AppWebDomain: 'security-automation-pann-dashboard-test.auth.us-east-1.amazoncognito.com',
    ClientId: '2apna2jt85j8ckk04645rneibg',
    RedirectUriSignIn: window.location.origin,
    RedirectUriSignOut: window.location.origin,
    TokenScopesArray: ['openid'],
    UserPoolId: 'us-east-1_bE8BTLJUK'
}

const betaAuthConfig = {
    AppWebDomain: 'security-automation-pann-dashboard-beta.auth.us-west-1.amazoncognito.com',
    ClientId: '7b5506gs6s0isonn4tnqm1c348',
    RedirectUriSignIn: window.location.origin,
    RedirectUriSignOut: window.location.origin,
    TokenScopesArray: ['openid'],
    UserPoolId: 'us-west-1_ufrMgIByb'
}

//Cognito config for prod environment
const prodAuthConfig = {
    AppWebDomain: 'security-automation-pann-dashboard.auth.us-west-2.amazoncognito.com',
    ClientId: '6qfpq49340nk6f0pklllhvektk',
    RedirectUriSignIn: window.location.origin,
    RedirectUriSignOut: window.location.origin,
    TokenScopesArray: ['openid'],
    UserPoolId: 'us-west-2_khTNarfOl'
}

const configAuthDir = {
    "test": testAuthConfig,
    "beta": betaAuthConfig,
    "prod": prodAuthConfig,
}

export function getAuthConfig() {
    return configAuthDir[getCurEnv()];
}

export const jiraIdPattern = /^[A-Z0-9]+-\d+$/;
export const cvePattern = /^CVE-\d{4}-\d{4,7}$/;