import {
    Badge,
    Box,
    Button,
    ColumnLayout,
    Container,
    ExpandableSection,
    Grid,
    Header,
    Multiselect,
    Pagination,
    Popover,
    SpaceBetween,
    Table,
} from "@amzn/awsui-components-react";
import { EmptyState, ValueWithLabel, getItemCntBadge, isEmptyArr, pageSize } from "../util";
import React, { memo } from "react";
import { gridDefinition } from "../../commons/layout";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { useDispatch } from "react-redux";
import { setSplitPanelData } from "../../features/splitPanel/splitPanelSlice";
import Breadcrumbs from "../../commons/breadcrumbs";
import { formatDate } from "../../features/report/dateConverter";
import { baseColumns, pannDecisionDetail } from "./reportDetailUtils";


export const PatchDetail = ({ detail }) => {

    const PatchOverview = ({ detail }) => {

        return <Container header={<Header variant="h2">Pann Analysis Overview</Header>}>
            <SpaceBetween size="s">
                <ColumnLayout columns={2}>
                    <ValueWithLabel label="Report Type">{detail.reportType}</ValueWithLabel>
                    <ValueWithLabel label="Scan Time">{formatDate(detail.timestamp)}</ValueWithLabel>
                </ColumnLayout>
                <ColumnLayout columns={2}>
                    <ValueWithLabel label="Patch Is Valid">{detail.patchValid.toString()}</ValueWithLabel>
                    <ValueWithLabel label="Patch File Name">{detail.fileName}</ValueWithLabel>
                </ColumnLayout>
                <ColumnLayout columns={1}>
                    <ExpandableSection
                        headerText={detail.devices.length + " Devices"}>
                        {isEmptyArr(detail.devices) ? "-" : detail.devices.sort().map(device => <Badge key={device} className={"device-badge"}>{device}</Badge>)}
                    </ExpandableSection>
                </ColumnLayout>
                <ColumnLayout columns={1}>
                    <ExpandableSection
                        headerText={"Patch File"}>
                        <Container fitHeight={true} className={"file-container"}>
                            <pre>{detail.fileData}</pre>
                        </Container>
                    </ExpandableSection>
                </ColumnLayout>
            </SpaceBetween>
        </Container>
    }

    const Detail = memo(({ detail }) => {
        let pos = 0;
        const tableEntries = detail.devices.map(device => {

            const analysis = detail.report[device];
            return {
                "pos": pos += 1,
                "device": device,
                "pannDecision": analysis.pannDecision,
                "mergeStatus": analysis.mergeStatus,
                "confidence": analysis.confidence,
                "fileMap": analysis.fileMap,
            }
        })
        const PatchTable = memo(({ tableEntries }) => {
            const [filteringDecisions, setFilteringDecisions] = React.useState([]);
            const [filteredEntries, setFilteredEntries] = React.useState(tableEntries);
            const { items, collectionProps, paginationProps } = useCollection(
                filteredEntries,
                {
                    pagination: { pageSize: pageSize },
                    sorting: {},
                }
            );
            const columnDefinitions =[
                {
                    id: "device",
                    header: "Device",
                    sortingField: "device",
                    cell: item => item.device
                },
                    ...baseColumns
                ]
            const [selectedItems, setSelectedItems] = React.useState([]);
            const dispatch = useDispatch();
            return <Table
                onSelectionChange={({ detail }) => {
                    setSelectedItems(detail.selectedItems);
                    const item = detail.selectedItems[0];
                    dispatch(setSplitPanelData({
                        "fileMap": item.fileMap,
                        "headerTxt": item.device,
                        "showPatches": false,
                    }));
                }
                }
                selectedItems={selectedItems}
                ariaLabels={{
                    selectionGroupLabel: "Items selection",
                    itemSelectionLabel: ({ selectedItems }, item) =>
                        item.pos
                }}
                columnDefinitions={columnDefinitions}
                {...collectionProps}
                items={items}
                loadingText="Loading resources"
                trackBy="pos"
                wrapLines
                selectionType="single"
                stickyHeader
                pagination={< Pagination {...paginationProps} ariaLabels="pagination" />}
                empty={
                    <EmptyState title={'No valid data'} />
                }
                header={<Header variant="h3" >PANN Analysis {getItemCntBadge(tableEntries.length)} <Popover triggerType="custom" position="right" content={pannDecisionDetail}>
                        <Button iconName="status-info" variant="icon" />
                    </Popover></Header>}
                filter={
                    <Multiselect selectedOptions={filteringDecisions.map((dec) => { return { label: dec, value: dec } })}
                        onChange={({ detail }) => {
                                const selectedDecisions = detail.selectedOptions.map(opt => opt.value);
                                setFilteringDecisions(selectedDecisions);
                                if (selectedDecisions.length === 0) {
                                    setFilteredEntries(tableEntries)
                                } else {
                                    setFilteredEntries(tableEntries.filter(entry => selectedDecisions.includes(entry.pannDecision)))
                                }
                            }
                        }
                        filteringType="auto"
                        noMatch="No matching item"
                        options={[...new Set(tableEntries.filter(entry => entry.pannDecision).map(entry => entry.pannDecision))].map(decision => { return { label: decision, value: decision } })}
                        placeholder={`Filter by PANN Decisions`}
                        empty="No options available"
                    />
                }
            />
        })
        return <PatchTable tableEntries={tableEntries} />
    })

    return <Box padding={{ vertical: 'xxl', horizontal: 's' }}>
        <Grid
            gridDefinition={[
                gridDefinition
            ]}
        >
            <SpaceBetween size="m">
                <Breadcrumbs isReportDetail={true} />
                <PatchOverview detail={detail} />
                <Detail detail={detail} />
            </SpaceBetween>
        </Grid>
    </Box>
}