import { Badge, Box } from "@amzn/awsui-components-react";

export const ValueWithLabel = ({ label, children }) => (
    <div>
        <Box variant="awsui-key-label">{label}</Box>
        <div>{children}</div>
    </div>
);


export const deepCopy = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

export const getLabelsFromTokens = (tokens) => {
    return tokens.filter(tokens => tokens.value !== "*").map(token => token.label);
}

export const getItemCntBadge = (cnt) => {
    return <Badge color="blue">{cnt}</Badge>
}

export const EmptyState = ({ title, subtitle, action }) => {
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {title}
            </Box>
            <Box variant="p" padding={{ bottom: 's' }} color="inherit">
                {subtitle}
            </Box>
            {action}
        </Box>
    );
}

export const isEmptyObj = (obj) => {
    if (!obj) {
        // null and undefined considered for empty obj
        return true;
    }
    return Object.keys(obj).length === 0;
}

export const isEmptyArr = (arr) => {
    if (!arr) {
        // null and undefined considered for empty arr
        return true;
    }
    return arr.length === 0;
}

export const pageSize = 50;