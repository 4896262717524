import { useParams } from "react-router-dom";
import { CveDeviceDetail } from "./cveDeviceDetail";
import React, { useCallback, useEffect, useState } from "react";
import StatusBar from "../../commons/statusBar";
import { AppLayout, Box, Spinner } from "@amzn/awsui-components-react";
import {fetchPatchFile, fetchReport} from "../../features/report/fetchReport";
import { Navigation } from "../../commons/navBar";
import { ReportSplitPanel } from "../../commons/splitPanel";
import { TicketDetail } from "./ticketDetail";
import { PatchDetail } from "./patchDetail";
import { Feedback } from "./feedback";



export function ReportDetail() {
    const [feedbackSubmitted, setFeedbackSumitted] = useState(true);    // prevent the feedback popping until we have the hasSubmitted data
    const time2DisplayFeedback = 1000 * 2 * 60;     // 2 min
    const { reportId } = useParams();
    const [feedbackVisible, setFeedbackVisible] = React.useState(false);
    const [statusMsgObj, setStatusMsgObj] = React.useState(null);
    const [content, setContent] = React.useState(<Box textAlign="center"> <Spinner size="large" /></Box>);

    const tryGetFeedbackInTimeout = useCallback(() => {
        if (!feedbackSubmitted) {
             setTimeout(() => {
                setFeedbackVisible(true);
            }, time2DisplayFeedback)
        } 
        
    }, [time2DisplayFeedback, feedbackSubmitted]);

    useEffect(() => {
        function getContent(reportData) {
            if (reportData) {
                setFeedbackSumitted(reportData.rating)
                tryGetFeedbackInTimeout();
                if (reportData.reportType === "CVE") {
                    return <CveDeviceDetail detail={reportData} />
                } else if (reportData.reportType === "JIRA") {
                    return <TicketDetail detail={reportData} />
                } else if (reportData.reportType === "PATCH") {
                    return <PatchDetail detail={reportData} />
                } else {
                    console.error("No support for report type: " + reportData.reportType)
                    return <StatusBar statusMsgObj={{ type: "error", message: "No support for report type: " + reportData.reportType }} />
                }
            }
        };

        async function fetchAndSetContent() {
            let res;
            try {
                res = await fetchReport(reportId);

                if (res.data.reportType === "PATCH"){
                    let fileData = await fetchPatchFile(res.data.patchFileUrl);
                    res.data.fileData = fileData;
                }
                console.log(res)

            } catch (e) {
                const errMsg = `Cannot fetch the specific report(id: ${reportId}): ${e}`
                console.error(errMsg);
                setStatusMsgObj({ type: "error", message: errMsg });
                return;
            }
            setContent(getContent(res.data));
        };
        fetchAndSetContent(reportId);

    }, [reportId, tryGetFeedbackInTimeout]);

    const [navigationOpen, setNavigationOpen] = React.useState(true);
    const [splitPanelOpen, setSplitPanelOpen] = React.useState(true)
    const [splitPanelSize, setSplitPanelSize] = React.useState(800);

    return (
        <AppLayout
            headerSelector="#h"
            disableContentPaddings={true}
            content={statusMsgObj ? <StatusBar statusMsgObj={statusMsgObj} /> : <Box> <Feedback visible={feedbackVisible} setVisible={setFeedbackVisible} reportId={reportId} 
                showFeedbackLater={tryGetFeedbackInTimeout} setFeedbackSumitted={setFeedbackSumitted}/>{content}</Box>}
            navigation={<Navigation activeHref="/myReport" />}
            navigationOpen={navigationOpen}
            navigationWidth={200}
            onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
            splitPanelOpen={splitPanelOpen}
            splitPanelSize={splitPanelSize}
            splitPanelPreferences={{position: "side"}}
            splitPanel={<ReportSplitPanel />}
            onSplitPanelResize={(event) => setSplitPanelSize(event.detail.size)}
            onSplitPanelToggle={(event) => setSplitPanelOpen(event.detail.open)}
            toolsHide={true}
        />
    );
}