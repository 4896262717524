import React, { useEffect } from "react";
import { fetchHistory } from "../../features/history/fetchHistory"
import { Modal, Table, Box, Pagination, Spinner } from "@amzn/awsui-components-react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { baseColumns } from "./reportDetailUtils";
import { formatDate } from "../../features/report/dateConverter";
import { EmptyState } from "../util";


export const History = ({visible, setVisible, cve, device}) => {
    const tableSize = 15;
    const [historyData, setHistoryData] = React.useState();

    useEffect(() => {
        async function fetchHistoryForCveDevice() {
            let res;
            try {
                res = await fetchHistory(cve, device);
            } catch (e) {
                const errMsg = `Cannot fetch the History. Please try again later.`
                console.error(errMsg);
                return;
            }
            console.log(res.data.history)
            setHistoryData(res.data);
        }
        fetchHistoryForCveDevice();
    }, ([cve, device]))

    const columnDefinitions = baseColumns.concat([
        {
            id: "patchUrl",
            header: "Patch Urls",
            cell: item => item.patchUrl === undefined ? "-" : item.patchUrl.sort().map((patch, idx) => <Box key={idx}>{patch}</Box>)
        },
        {
            id: "fileMap",
            header: "File Affected",
            cell: item => item.fileMap === undefined ? "-" : item.fileMap.sort().map((file, idx) => <Box key={idx}>{file}</Box>)
        },
        {
            id: "timestamp",
            header: "Timestamp",
            sortingField: "timestamp",
            cell: item => item.timestamp === undefined ? "-" : formatDate(item.timestamp)
        }
    ]);

    const { items, collectionProps, paginationProps } = useCollection(
        historyData ? historyData.history.filter(item => item.dataChange) : [],
        {
            pagination: { pageSize: tableSize },
            sorting: {},
        }
    );

    return (
        
        <Modal
            onDismiss={() => setVisible(false)}
            visible={visible}
            header={`${cve}/${device} History`}
            size="max"
        >
            {(historyData && historyData.cve === cve && historyData.device === device) ? <Table
                variant="borderless"
                {...collectionProps}
                columnDefinitions={columnDefinitions}
                items={items}
                wrapLines
                stickyHeader
                pagination={< Pagination {...paginationProps} ariaLabels="pagination" />}
                empty={
                    <EmptyState title={'No history data'} />
                }
            /> : <Box textAlign="center"> <Spinner size="large" /> </Box> }
        </Modal>
    )
}