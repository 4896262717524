import * as React from "react";
import Tabs from "@amzn/awsui-components-react/polaris/tabs";
import { AppLayout, Box, Grid } from "@amzn/awsui-components-react";
import { Navigation } from "../../commons/navBar";
import { gridDefinition } from '../../commons/layout';
import { CveDeviceTab } from "./cveDeviceTab";
import { PatchDeviceTab } from "./PatchDeviceTab";
import { TicketTab } from "./TicketTab";
import { fetchDevices } from "../../features/device/fetchDevices";
import {useSelector} from "react-redux";
import {selectUserAlias} from "../../features/auth/userAliasSlice";



const Content = ({ devices }) => {
    const userAlias = useSelector(selectUserAlias);
    return <Box padding={{ vertical: 'xxl', horizontal: 's' }}>
        <Grid
            gridDefinition={[
                gridDefinition
            ]}
        >
            <Tabs
                tabs={userAlias.startsWith("ext-") ?
                    [
                        {
                            label: "Scan Security Tickets",
                            id: "0",
                            content: <TicketTab />
                        }
                    ]
                    : [
                    {
                        label: "Scan CVE(s)",
                        id: "0",
                        content: <CveDeviceTab devices={devices}/>
                    },
                    {
                        label: "Scan Security Tickets",
                        id: "1",
                        content: <TicketTab />
                    },
                    {
                        label: "Upload a Patch File",
                        id: "2",
                        content: <PatchDeviceTab devices={devices}/>,
                    }
                ]}
                variant="container"
            />
        </Grid>
    </Box>
}

export function ScanPage() {
    React.useEffect(() => {
        const fetchDevicesData = async () => {
            const response = await fetchDevices().catch(e => {
                console.log("Got error while fetching devices: " + e);
                setDevices(null);
                return;
            });
            if (response) {
                setDevices(response.data.devices)
            }
        }

        fetchDevicesData();
    }, [])

    const [navigationOpen, setNavigationOpen] = React.useState(true);
    const [devices, setDevices] = React.useState([]);


    return (
        <AppLayout
            headerSelector="#h"
            disableContentPaddings={true}
            content={<Content devices={devices}/>}
            navigation={<Navigation activeHref="/scan" />}
            navigationOpen={navigationOpen}
            navigationWidth={200}
            onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
            toolsHide={true}
        />
    );
}