import { getApiURL } from '../../utils/envVarsReader';
import axios from "axios";
import { getJwtToken } from '../auth/Auth';


/**
 * Send the payload create new PANN report
 * @param {Object} payload
 * @returns {Response} Response object returned from calling lambda
 */
export async function createNewReport(payload) {
    const options = {
        headers: {
            'Authorization': `Bearer ${getJwtToken()}`,
            'Content-Type': 'application/json'
        },
    }

    let response;
    try {
        response = await axios.post(`${getApiURL()}/report`, payload, options);
    } catch (e) {
        if (e.response) {
            const err = e.response.data.error;
            throw err || (e.response.status + ": " + e.response.statusText)
        }
    }

    return response;
}

/**
 * This method upload file object using the presignedUrl to s3 bucket
 * @param {String} presignedUrl url for uploading to s3
 * @param {File} file file object to upload
 * @returns {Response} the response object from presignedUrl PUT reqeuest
 */
export const uploadFileToS3 = async (presignedUrl, file) => {
    const options = {
        headers: {
            'Content-Type': file.type,
        }
    };

    let res;
    try {
        res = await axios.put(presignedUrl, file, options);
    } catch (e) {
        throw e;
    }
    return res;
}


