import * as React from 'react';
import {Badge, Button} from '@amzn/awsui-components-react';
import {formatDate} from "../../features/report/dateConverter";

export function getMatchesCountText(count) {
    return count === 1 ? `1 match` : `${count} matches`;
}

function createLabelFunction(columnName) {
    return ({ sorted, descending }) => {
        const sortState = sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted';
        return `${columnName}, ${sortState}.`;
    };
}

export const columnDefinitions = [
    {
        id: 'reportType',
        header: 'Type',
        cell: item => item.reportType,
        ariaLabel: createLabelFunction('Report Type'),
        sortingField: 'reportType',
        width: 100
    },
    {
        id: 'state',
        header: 'State',
        cell: item => <Badge color={item.state === "READY" ? "green": ( item.state === "PROCESSING" ? "blue": "red")}> {item.state} </Badge>,
        ariaLabel: createLabelFunction('State'),
        sortingField: 'state',
        width: 130
    },
    {
        id: 'cveList',
        header: 'CVE(s)',
        cell: item => item.cveList? item.cveList.sort().join(", ") : "-",
        ariaLabel: createLabelFunction('Cve List'),
        sortingField: 'cveList',
    },
    {
        id: 'devices',
        header: 'Device(s)',
        cell: item => item.devices ? item.devices.sort().join(", ") : "-",
        ariaLabel: createLabelFunction('Devices'),
        sortingField: 'devices',
    },
    {
        id: 'jiraIds',
        header: 'Jira Id(s)',
        cell: item => item.jiraList ? item.jiraList.sort().join(", ") : "-",
        ariaLabel: createLabelFunction('Jira List'),
        sortingField: 'jiraIds',
    },
    {
        id: 'fileName',
        header: 'File Name',
        cell: item => item.fileName ? item.fileName: "-",
        ariaLabel: createLabelFunction('File Name'),
        sortingField: 'fileName',
    },
    {
        id: 'timestamp',
        header: 'Timestamp',
        cell: item => formatDate(item.timestamp),
        ariaLabel: createLabelFunction('Timestamp'),
        sortingComparator: (a, b) => a.timestamp.localeCompare(b.timestamp)
    },
    {
        id: 'viewReport',
        header: 'Report',
        cell: item => <Button wrapText={false} disabled={item.state !== "READY"} href={"/myReport/"+item.reportId}> View </Button>,
        width: 200,
    }

];

export const paginationLabels = {
    nextPageLabel: 'Next page',
    pageLabel: pageNumber => `Go to page ${pageNumber}`,
    previousPageLabel: 'Previous page',
};

const pageSizePreference = {
    title: 'Select page size',
    options: [
        { value: 15, label: '15 reports' },
        { value: 30, label: '30 reports' },
        { value: 50, label: '50 reports' },
        { value: 100, label: '100 reports' },
    ],
};

const visibleContentPreference = {
    title: 'Select visible content',
    options: [
        {
            label: 'Main properties',
            options: columnDefinitions.map(({ id, header }) => ({ id, label: header, editable: id !== 'devices' && id !== "viewReport" && id !== "state"})),
        },
    ],
};
export const collectionPreferencesProps = {
    pageSizePreference,
    visibleContentPreference,
    cancelLabel: 'Cancel',
    confirmLabel: 'Confirm',
    title: 'Preferences',
};
